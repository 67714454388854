<template>
    <div class="content">
        <div v-for="item in details" :key="item.id">
         <div class="title">{{item.questionTitle}}</div>
         <div class="details">{{item.questionContent}}</div>
         <div class="bottom">
             <div class="bottom1" @click="answer()">
                 <span class="bottom1-icon"></span>
                 <span class="bottom_words">我来答</span>
                 </div>
             <div class="bottom2">
                 <span id="bottom2-icon"></span>
                 <span class="bottom_words">分享</span>
             </div>
                <!-- 收藏 -->
            <div class="icon">
                      <span class="refresh-icon3"></span>
                      <span>{{item.collectNum}}</span>
                      <span>收藏</span>
            </div>
            <div class="bottom2">
                      <span class="refresh-icon4"></span>
                      <span>邀请回答</span>
            </div>
         </div>
      </div>
        <div v-if="visible" class="text">
        <editor-bar
          v-model="content"
          :isClear="isClear"
        
          @change="change"
        ></editor-bar>
        <span class="right1">
            <span class="refresh-icon"></span>
            <span class="aa">回答自动保存</span>
        </span>
        <el-button type="primary" class="right-icon">提交回答</el-button>
    </div>
    </div>
</template>
<script>
import EditorBar from "@/components/wangEnduit/index";
export default {
    components: { EditorBar },
    data(){
        return{
            content:"",
             isClear: false,
            visible: false,
        }
    },
    props:{
        details:{
            type:Object,
            required:true
        }
    },
    methods:{
    async answer(){
        this.visible=!this.visible;
    },
     change(val) {
      console.log(val);
    },
    }
}
</script>
<style scoped>
.content{
    display: inline-block;
    width: 100%;
    /* padding: 20px; */

    /* border: 1px solid yellowgreen; */
}
.title{
    width: 95%;
    font-size: 17px;
    font-weight: bold;
    line-height: 25px;
    /* margin-top: 20px; */
    margin-left: 20px;
}
.details{
    width: 95%;
    font-size: 14px;
    line-height: 25px;
    margin-left: 20px;
    margin-right: 20px;
    /* color: darkgray; */
    /* border:1px solid blue */

}
.bottom{
    font-size:14px;
    width: 95%;
    display: inline-block;
    margin-left: 20px;
    margin-top: 20px;
    /* border: 1px solid goldenrod; */
    margin-bottom: 20px;
}
.bottom1,.bottom2{
    width: 100px;
    height:26px;
    border: none;
    text-align: center;
    display: inline-block;
    /* border: 1px solid red; */
}
.bottom1{
padding: 5px;
background-color: #0084FF;
border-radius:2px;
}
.bottom2{
margin-left: 30px;

}
.bottom1-icon{
    /* margin-top: 10px; */
     width: 24px;
     height:24px;
     display: inline-block;
     background-repeat: no-repeat;
     background-image:url('../../../../assets/icon/write.png');
     position: relative;
     top:7px;
     left: 8px;
     border:none;
}
#bottom2-icon{
     width: 20px;
     height:20px;
     display: inline-block;
     background-repeat: no-repeat;
     background-image:url('../../../../assets/icon/share1.png');
     position: relative; 
     top:10px;
     left: 5px;
     /* border:1px solid red */
}
.bottom_words{
     height:30px;
     line-height: 30px;
  

}
.bottom1 .bottom_words{
color: white;
position: relative;
top: -5px;

}
.bottom2 .bottom_words{
color: gray;

}
.icon{
    display: inline-block;
    /* width: 120px; */
    height:30px;
}

.refresh-icon3{
    width: 20px;
    height:20px;
    display: inline-block;
    background-image: url('../../../../assets/icon/Collection.png');
    background-repeat: no-repeat;
    position: relative;
    top: 7px;
    left: 5px;
        /* border:1px solid red; */
}
.refresh-icon4{
    width: 25px;
    height:25px;
    display: inline-block;
    background-image: url('../../../../assets/invitation.png');
    background-repeat: no-repeat;
    position: relative;
    top: 10px;
    left: 5px;
        /* border:1px solid red; */
}
.text{
    width: 100%;
    /* height: 200px; */
    display: inline-block;
    /* border: 1px solid rgba(180,180,180,1); */
}
.right-icon{
    /* text-align: right; */
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}
.right1 .refresh-icon{
display: inline-block;
height:15px;
width: 15px;
background-image: url('../../../../assets/icon/change2.png'); 
background-size: 15px 15px;
background-repeat: no-repeat;
position: relative;
top:30px;
margin-right: 3px;
border:0;
}
.right1{
    font-size:14px;
font-family:Microsoft YaHei;
font-weight:300;
color:rgba(89,89,89,1);
line-height:30px;
}
.aa{
    position: relative;
    top: 28px;
}
</style>