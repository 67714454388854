<template>

<div class=contet>
          <!-- 等待解答 -->
    <div class="problem" v-for="(item,index) in problem" :key="index">
           <!-- 等待解答_问题 -->
           <div class="wait——problem">
               <a class="title" href="javaScript:void(0)">{{item.title}}</a>
               <span class="img" v-if="item.img"></span>
               </div>
           <!-- 提问的人 -->
           <div class="ask">
            <!-- 我来答 -->
           <div class="bottom">
               <a href="javaScript:void(0)">
                 <span class="bottom-icon"></span>
                 <span class="bottom_words">我来答</span>
               </a>
           </div>
           
    </div>
</div>
</div>
</template>
<script>
export default {
     data(){
        return{
            problem:[{
              index:1,
              title:'矩阵分解算法能否保证收敛到全局……',
              img:require('../../../assets/icon/矩形 6.png'),
        
               },
               {
              index:2,
              title:'矩阵分解算法能否保证收敛到全局……2',
                   img:require('../../../assets/icon/矩形 6.png'),
           
               },
               {
              index:3,
              title:'矩阵分解算法能否保证收敛到全局……3',
              img:require('../../../assets/icon/矩形 6.png'),
            
               },
               {
              index:4,
              title:'矩阵分解算法能否保证收敛到全局……4',
              img:require('../../../assets/icon/矩形 6.png'),
           
               },
               {
              index:5,
              title:'矩阵分解算法能否保证收敛到全局……5',
           
               }
               ]
               
        }
 }   
}
</script>
<style scoped>
*{
    text-decoration: none;
}
.contet{
width: 100%;
border: 1px solid red;
}
.problem{
width: 95%;
}
.bottom{
    width: 100%;
    height:30px;
    border: none;
    text-align: right;
    display: inline-block;
    /* margin-top: 30px; */
    /* border:1px solid red */
    /* padding-bottom: 50px; */
}
.bottom-icon{
    /* margin-top: 10px; */
     width: 20px;
     height:20px;
     /* border:1px solid black; */
     background-image: url('../../../assets/icon/write3.png');
     background-repeat: no-repeat;
     /* position: relative;
     top:9px;
     left: 10px; */
     display: inline-block;
}
.wait——problem{
font-size: 15px;
/* border: 1px solid red; */
width: 100%;
display: inline-block;
}
.ask{
    display: inline-block;
    width: 100%;
    font-size: 12px;
    color: #0084FF;
    height: 30px;
    line-height: 30px;
    margin-top: 15px;
    margin-bottom: 10px;
    /* border: 1px solid red; */
    text-align: center;
}
.tu{
width: 20px;
height:20px;
float: left;
margin-left: 5px;
border-radius: 50%;
}
.title{
text-align: left;
width: 90%;
display: inline-block;
color: #767676;
/* border:1px solid green */
}
.title:hover{
    color: coral;
}
.img{
    text-align: right;
    /* margin-top: 10px; */
     width: 20px;
     height:20px;
     line-height: 20px;
     background-image: url('../../../assets/icon/img.png');
     background-repeat: no-repeat;
     position: relative;
     top:9px;
     left: 10px;
     display: inline-block;
     vertical-align: top;
     /* position: relative;
     left: 35px; */
     border: 1px solid red;
}
.ask_people{
float: left;
margin-left: 5px;
}
.bottom_words{
    margin-left: 6px;
    color: #0084FF;
    /* text-align: right; */
}
</style>