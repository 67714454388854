<template>
        <!-- 热点问题 _内容 -->
    <div class="follow">
        <!-- 左边内容 -->
         <div class="left">
             <!-- 热点内容_导航 -->
             <!-- 热点内容 -->
             <div class="content">
                  <V-Content
                  :details="details"
                  ></V-Content>
             </div>
             <!-- <热点内容详情 -->
             <div class="details">
                 <V-Details></V-Details>
             </div>
         </div>

         <!-- 右边内容 -->
        <div class="right">
            <!-- 头部 -->
            <div>
               <!-- 等待解答 -->
               <div class="answer"> 
                  <div class="left1">等待解答</div>
                     <div class="right1">
                        <span class="refresh-icon"></span>
                        <span class="right2">换一换</span>
                   </div>
                </div>
               <!-- 等待解答_问题 -->
               <div>
                  <V-Problem></V-Problem>
               </div>
        </div>
           
        </div>

    </div>
</template>
<script>
import { QUERY } from "@/services/dao.js";
import Content from "@/views/wisdom-answer/answerDetail/hotspot-content/index.vue"
import Details from "@/views/wisdom-answer/answerDetail/hotspot-details/index.vue"
import Problem from "@/components/hotspot/hotspot-problem/index.vue"
export default {
    data(){
      return{
       details:[],
       problemid:0,
      }

    },
    created(){
        //加载问题详情
        this.problem();
    },
    methods:{
  async problem(){
           this.problemid=this.$route.query.id; 
          let info=  await  QUERY("post","",'WdQuestion(where: {id: {_eq: '+this.problemid+'}}) {  id    questionTitle questionContent  collectNum }');
            console.log(info)
            //alert(info)
            this.details.splice(0,this.details.length);
            for(let i=0;i<info.data.WdQuestion.length;i++){
                this.details.push({
                    id:info.data.WdQuestion[i].id,
                    questionTitle:info.data.WdQuestion[i].questionTitle,
                    questionContent:info.data.WdQuestion[i].questionContent,
                    collectNum:info.data.WdQuestion[i].collectNum
                });
            } 

        }
    },
    components:{
        "V-Content":Content,
        "V-Details":Details,
        "V-Problem":Problem
    }
}
</script>
<style scoped>
*{
margin: 0;
padding: 0;

}
.follow{
width: 1110px;
margin: auto;
}
.left{
width:760px;
/* float: left; */
display: inline-block;
}
.right{
margin-left:5px;
width: 320px;
display: inline-block;
/* float: left; */
/* border:1px solid red; */
padding-left: 20px;
background-color: white;
vertical-align: top;
margin-top: 40px;
}
.head{
width: 100%;
height: 40px;
line-height: 40px;
}
.content{
width: 100%;
background-color: white;
display: inline-block;
/* border: 1px solid red; */
/* margin-bottom: 20px; */
margin-top: 40px;
}
.details{
width: 100%;
margin-top: 10px;
background-color: white;
display: inline-block;
/* padding-left: 20px; */

}

.answer{
width:100%;
/* border:1px solid blue; */
display: inline-block;
margin-top: 30px;
margin-bottom: 20px;
}
.left1{
display: inline-block;
/* margin-left: ; */
font-weight: bold;
font-size: 15px;
}
.right1{
display: inline-block;
margin-left: 150px;
height:25px;
line-height: 25px;
}
.right1 .refresh-icon{
display: inline-block;
height:22px;
width: 22px;
background-image: url('../../../assets/icon/change2.png'); 
background-repeat: no-repeat;
position: relative;
top:6px;
margin-right: 3px;
border:0;
}
.right2{
font-size: 12px;
color: darkgray;
    }

    .Navigation{
width: 100%;
height:20px;
/* border:1px solid cadetblue; */
font-size: 15px;
font-weight: bold;

}
.Navigation>span{
/* float: left; */
display:inline-block;
margin-left: 5px;
}
</style>